import {
  setActionNodeTemplate,
  setDialogueGraphBlueprints,
  setDialogueGraphDataPublishedAt,
  setDialogueGraphDataPublishedStatus,
  setDialogueGraphDataSavedAt,
  setDialogueGraphDataSavedStatus,
  setDialogueGraphDataTrainedAt,
  setDialogueGraphInfo,
  setDialogueGraphs,
  setDialogueGraphVariables,
  setDialogueNodeSearchQuery,
  setDialogueSelectedGraphNode,
  setDialogueUrls,
  setEedNotificationData,
  setGraphId,
  setGraphUndoRedo,
  setProactiveMessage,
  undoableDialogueGraphData,
} from "./dialogues";
import { setSynonyms } from "./synonyms";
import { setSnackbarMessages } from "./snackbar";
import { setTickets } from "./tickets";
import {
  selectTickets,
  setForwardedTickets,
  setInProgressTickets,
  setClosedTickets,
  setOpenTickets,
  setPendingTickets,
  setSolvedTickets,
  setRentalUnitTicketCountByStatus,
} from "./rentalUnitTickets";
import { setKeycloak } from "./keycloak";
import {
  setChannelLimitations,
  setChannelShareLinks,
  setDialogueGraphChannel,
  setFacebookPages,
  setFacebookToken,
  setUsedFacebookPage,
  setUsedWebChannelConfig,
} from "./channels";
import { setFetchState } from "./requests";
import { setMSLoginLink, setMSLoginState } from "./integrations";
import ActionConstants from "../constants";
import {
  setAgentAssistResponse,
  setAttachmentFile,
  setConversation,
  setConversationAttachments,
  setConversationIdentity,
  setConversations,
  setConversationsCount,
  setConversationsCountAssigned,
  setConversationsCountUnassigned,
  setConversationTracker,
  setTenantIdentity,
  setTicketAnswer,
  setTranslatedConversation,
  setCopiedConversation,
} from "./conversations";
import { setThemeConfig, setUploadedFileUrl } from "./files";
import { setFaqs, setTrainStatus } from "./faqs";
import { setJumpGraphs } from "./jumpGraphs";
import {
  setConversationSidebarState,
  setCopilotDrawerState,
  setDrawerState,
  setLanguage,
  setTenant,
  setUiPreferences,
} from "./layout";
import { setCannedResponse, setCannedResponses } from "./cannedResponses";
import { setLabel, setLabels, setLabelsForConversation } from "./labels";
import {
  setTagCategories,
  setTagProblems,
  setTagReasons,
  setTags,
  setTagsBySenderId,
  setTagTopics,
} from "./tags";
import { setCaseStatusValues, setSelectedCaseStatus } from "./cases";
import { setContact, setContacts } from "./contacts";
import {setSubgroups, setUser, setUsers, setUserSettings} from "./users";
import {
  setCampaignTypes,
  setOneOffCampaign,
  setOneOffCampaigns,
  setOngoingCampaign,
  setOngoingCampaigns,
} from "./campaigns";
import { setMessagePrediction } from "store/reducers/nlp";
import { setDecisionTrees } from "store/reducers/decisionTrees";
import { ROLES } from "lib/constants";
import { ReduxState } from "types/index";
import {
  setAdminResponse,
  setQnaDownload,
  setQnaUpload,
  setTriggerNodesFetchedBySentence,
} from "store/reducers/admin";
import {
  setDocument,
  setDocumentConversationId,
  setDocumentsInfo,
  setDocumentsCount,
  setDocumentsUsage,
  setDocumentViewFetchStatus,
  setDocumentViewport,
  setDownloadDocument,
  setPdfData,
  setMessageLimitReached,
  setUserConversations,
  setAnnotations,
  setAnnotationsHighlightColor,
  setStorageLimitReached,
  setIndexingDocuments,
  setCopilotColorMode,
  setCopilotAiConfig,
  setNewCreatedChatId,
  setSelectedCopilotChat,
  setLoadingNewDocument,
} from "store/reducers/smartDoc/documents";
import {
  setCollection,
  setCollections,
  setIsTrashCollection,
  setCollectionsUpsertStatus,
} from "store/reducers/smartDoc/collections";
import { setRentalUnitTenants } from "store/reducers/rentalUnitTenants";
import { setRentalUnitArticles } from "store/reducers/rentalUnitArticles";
import {
  setCopilotDashboardConfig,
  setIsCopilotReady,
} from "store/reducers/smartDoc/copilot_config";

const appReducer = (currentState: ReduxState, action: any) => {
  return {
    keycloakToken: setKeycloak(
      currentState ? currentState.keycloakToken : null,
      action
    ),
    drawerState: setDrawerState(
      currentState ? currentState.drawerState : true,
      action
    ),
    uiPreferences: setUiPreferences(
      currentState ? currentState.uiPreferences : {},
      action
    ),
    copilotDrawerState: setCopilotDrawerState(
      currentState ? currentState.copilotDrawerState : true,
      action
    ),
    copilotColorMode: setCopilotColorMode(
      currentState ? currentState.copilotColorMode : "dark",
      action
    ),
    conversationSidebarState: setConversationSidebarState(
      currentState ? currentState.conversationSidebarState : "",
      action
    ),
    language: setLanguage(currentState ? currentState.language : null, action),
    tenant: setTenant(currentState ? currentState.tenant : null, action),
    dialogueGraphData: undoableDialogueGraphData(
      currentState?.dialogueGraphData,
      action
    ),
    graphUndoRedo: setGraphUndoRedo(
      currentState ? currentState.graphUndoRedo : false,
      action
    ),
    dialogueGraphInfo: setDialogueGraphInfo(
      currentState?.dialogueGraphInfo,
      action
    ),
    dialogueGraphs: setDialogueGraphs(
      currentState ? currentState.dialogueGraphs : null,
      action
    ),
    dialogueUrls: setDialogueUrls(
      currentState ? currentState.dialogueUrls : null,
      action
    ),
    dialogueGraphBlueprints: setDialogueGraphBlueprints(
      currentState ? currentState.dialogueGraphBlueprints : null,
      action
    ),
    dialogueGraphId: setGraphId(
      currentState ? currentState.dialogueGraphId : null,
      action
    ),
    dialogueGraphChannel: setDialogueGraphChannel(
      currentState ? currentState.dialogueGraphChannel : null,
      action
    ),
    channelLimitations: setChannelLimitations(
      currentState ? currentState.channelLimitations : {},
      action
    ),
    channelShareLinks: setChannelShareLinks(
      currentState ? currentState.channelShareLinks : {},
      action
    ),
    selectedGraphNode: setDialogueSelectedGraphNode(
      currentState ? currentState.selectedGraphNode : [],
      action
    ),
    synonyms: setSynonyms(currentState ? currentState.synonyms : [], action),
    tickets: setTickets(currentState ? currentState.tickets : [], action),
    faqs: setFaqs(currentState ? currentState.faqs : [], action),
    trainStatus: setTrainStatus(
      currentState ? currentState.trainStatus : null,
      action
    ),
    jumpGraphs: setJumpGraphs(
      currentState ? currentState.jumpGraphs : [],
      action
    ),

    dialogueSavedAt: setDialogueGraphDataSavedAt(
      currentState ? currentState.dialogueSavedAt : new Date().getTime(),
      action
    ),
    dialogueSavedStatus: setDialogueGraphDataSavedStatus(
      currentState ? currentState.dialogueSavedStatus : "success",
      action
    ),
    dialoguePublishedAt: setDialogueGraphDataPublishedAt(
      currentState ? currentState.dialoguePublishedAt : null,
      action
    ),
    dialogueTrainedAt: setDialogueGraphDataTrainedAt(
      currentState ? currentState.dialogueTrainedAt : null,
      action
    ),
    dialoguePublishedStatus: setDialogueGraphDataPublishedStatus(
      currentState ? currentState.dialoguePublishedStatus : "success",
      action
    ),
    actionNodeTemplate: setActionNodeTemplate(
      currentState ? currentState.actionNodeTemplate : {},
      action
    ),
    conversationTracker: setConversationTracker(
      currentState ? currentState.conversationTracker : null,
      action
    ),
    agentAssistResponse: setAgentAssistResponse(
      currentState ? currentState.agentAssistResponse : null,
      action
    ),
    uploadedFile: setUploadedFileUrl(
      currentState ? currentState.uploadedFile : null,
      action
    ),
    themeConfig: setThemeConfig(
      currentState ? currentState.themeConfig : null,
      action
    ),
    dialogueVariables: setDialogueGraphVariables(
      currentState ? currentState.dialogueVariables : null,
      action
    ),
    channelFacebookPages: setFacebookPages(
      currentState ? currentState.channelFacebookPages : null,
      action
    ),
    channelUsedFacebookPage: setUsedFacebookPage(
      currentState ? currentState.channelUsedFacebookPage : null,
      action
    ),
    channelFacebookToken: setFacebookToken(
      currentState ? currentState.channelFacebookToken : null,
      action
    ),
    channelUsedWebConfig: setUsedWebChannelConfig(
      currentState ? currentState.channelUsedWebConfig : null,
      action
    ),
    fetchState: setFetchState(
      currentState ? currentState.fetchState : null,
      action
    ),
    proactiveMessage: setProactiveMessage(
      currentState ? currentState.proactiveMessage : null,
      action
    ),
    eedNotificationData: setEedNotificationData(
      currentState ? currentState.eedNotificationData : null,
      action
    ),
    conversationIdentities: setConversationIdentity(
      currentState ? currentState.conversationIdentities : {},
      action
    ),
    tenantIdentity: setTenantIdentity(
      currentState ? currentState.tenantId : {},
      action
    ),
    cannedResponses: setCannedResponses(
      currentState ? currentState.cannedResponses : null,
      action
    ),
    cannedResponse: setCannedResponse(
      currentState ? currentState.cannedResponse : null,
      action
    ),
    labels: setLabels(currentState ? currentState.labels : null, action),
    label: setLabel(currentState ? currentState.label : null, action),
    conversationLabels: setLabelsForConversation(
      currentState ? currentState.conversationLabels : null,
      action
    ),
    caseStatusValues: setCaseStatusValues(
      currentState ? currentState.caseStatusValues : null,
      action
    ),
    selectedCaseStatus: setSelectedCaseStatus(
      currentState ? currentState.selectedCaseStatus : null,
      action
    ),
    conversations: setConversations(
      currentState ? currentState.conversations : null,
      action
    ),
    conversationsCount: setConversationsCount(
      currentState ? currentState.conversationsCount : null,
      action
    ),
    conversationsCountAssigned: setConversationsCountAssigned(
      currentState ? currentState.conversationsCountAssigned : null,
      action
    ),
    conversationsCountUnassigned: setConversationsCountUnassigned(
      currentState ? currentState.conversationsCountUnassigned : null,
      action
    ),
    conversation: setConversation(
      currentState ? currentState.conversation : null,
      action
    ),
    conversationAttachments: setConversationAttachments(
      currentState ? currentState.conversationAttachments : null,
      action
    ),
    attachmentFile: setAttachmentFile(
      currentState ? currentState.attachmentFile : null,
      action
    ),
    ticketAnswer: setTicketAnswer(
      currentState ? currentState.attachmentFile : null,
      action
    ),
    contacts: setContacts(currentState ? currentState.contacts : null, action),
    contact: setContact(currentState ? currentState.contact : null, action),
    users: setUsers(currentState ? currentState.users : null, action),
    user: setUser(currentState ? currentState.user : null, action),
    userRoles: [
      ROLES.ACCOUNT_ADMIN,
      ROLES.OPERATOR,
      ROLES.CONTENT_EDITOR,
      ROLES.SMARTDOC_USER,
    ],
    userSettings: setUserSettings(
      currentState ? currentState.userSettings : null,
      action
    ),
    subgroups : setSubgroups(currentState? currentState.subgroups : null, action),

    campaignTypes: setCampaignTypes(
      currentState ? currentState.campaignTypes : null,
      action
    ),
    oneOffCampaigns: setOneOffCampaigns(
      currentState ? currentState.oneOffCampaigns : null,
      action
    ),
    oneOffCampaign: setOneOffCampaign(
      currentState ? currentState.oneOffCampaign : null,
      action
    ),
    ongoingCampaigns: setOngoingCampaigns(
      currentState ? currentState.ongoingCampaigns : null,
      action
    ),
    ongoingCampaign: setOngoingCampaign(
      currentState ? currentState.ongoingCampaign : null,
      action
    ),
    dialogueNodeSearchQuery: setDialogueNodeSearchQuery(
      currentState ? currentState.dialogueNodeSearchQuery : null,
      action
    ),
    messagePrediction: setMessagePrediction(
      currentState ? currentState.messagePrediction : null,
      action
    ),
    decisionTrees: setDecisionTrees(
      currentState ? currentState.decisionTrees : null,
      action
    ),
    translatedConversation: setTranslatedConversation(
      currentState ? currentState.translatedConversation : null,
      action
    ),
    copiedConversationId: setCopiedConversation(
      currentState ? currentState.copiedConversationId : null,
      action
    ),
    MSLoginLink: setMSLoginLink(
      currentState ? currentState.MSLoginLink : null,
      action
    ),
    MSLoginState: setMSLoginState(
      currentState ? currentState.MSLoginState : null,
      action
    ),
    qnaDownloadFile: setQnaDownload(
      currentState ? currentState.qnaDownloadFile : null,
      action
    ),
    qnaUploadFile: setQnaUpload(
      currentState ? currentState.qnaUploadFile : null,
      action
    ),
    adminResponse: setAdminResponse(
      currentState ? currentState.adminResponse : null,
      action
    ),
    triggerNodesFetchedBySentence: setTriggerNodesFetchedBySentence(
      currentState ? currentState.triggerNodesFetchedBySentence : null,
      action
    ),
    tagCategories: setTagCategories(
      currentState ? currentState.tagCategories : null,
      action
    ),
    tagTopics: setTagTopics(
      currentState ? currentState.tagTopics : null,
      action
    ),
    tagReasons: setTagReasons(
      currentState ? currentState.tagReasons : null,
      action
    ),
    tagProblems: setTagProblems(
      currentState ? currentState.tagProblems : null,
      action
    ),
    tagsBySenderId: setTagsBySenderId(
      currentState ? currentState.tagsBySenderId : null,
      action
    ),
    tags: setTags(currentState ? currentState.tags : null, action),
    snackbarMessages: setSnackbarMessages(
      currentState ? currentState.snackbarMessages : [],
      action
    ),
    openTickets: setOpenTickets(
      currentState ? currentState.openTickets : null,
      action
    ),
    solvedTickets: setSolvedTickets(
      currentState ? currentState.solvedTickets : null,
      action
    ),
    pendingTickets: setPendingTickets(
      currentState ? currentState.pendingTickets : null,
      action
    ),
    inProgressTickets: setInProgressTickets(
      currentState ? currentState.inProgressTickets : null,
      action
    ),
    forwardedTickets: setForwardedTickets(
      currentState ? currentState.forwardedTickets : null,
      action
    ),
    closedTickets: setClosedTickets(
      currentState ? currentState.closedTickets : null,
      action
    ),
    selectedTickets: selectTickets(
      currentState ? currentState.selectedTickets : [],
      action
    ),
    rentalUnitTenants: setRentalUnitTenants(
      currentState ? currentState.rentalUnitTenants : null,
      action
    ),
    rentalUnitArticles: setRentalUnitArticles(
      currentState ? currentState.rentalUnitArticles : null,
      action
    ),
    ticketCountByStatus: setRentalUnitTicketCountByStatus(
      currentState ? currentState.ticketCountByStatus : null,
      action
    ),
    documentsInfo: setDocumentsInfo(
      currentState ? currentState.documentsInfo : null,
      action
    ),
    documentsCount: setDocumentsCount(
      currentState ? currentState.documentsCount : null,
      action
    ),
    documentsUsage: setDocumentsUsage(
      currentState ? currentState.documentsUsage : null,
      action
    ),
    document: setDocument(currentState ? currentState.document : null, action),
    collection: setCollection(
      currentState ? currentState.collection : null,
      action
    ),
    collections: setCollections(
      currentState ? currentState.collections : null,
      action
    ),
    collectionsUpsertStatus: setCollectionsUpsertStatus(
      currentState
        ? currentState.collectionsUpsertStatus
        : {
            addingDocuments: { status: "none" },
            removingDocuments: [{ status: "none" }],
          },
      action
    ),
    isTrashCollection: setIsTrashCollection(
      currentState ? currentState.isTrashCollection : null,
      action
    ),
    downloadDocument: setDownloadDocument(
      currentState ? currentState.downloadDocument : null,
      action
    ),
    documentViewport: setDocumentViewport(
      currentState ? currentState.documentViewport : null,
      action
    ),
    userConversations: setUserConversations(
      currentState ? currentState.userConversations : null,
      action
    ),
    currentDocumentConversationId: setDocumentConversationId(
      currentState ? currentState.currentDocumentConversationId : null,
      action
    ),
    pdfData: setPdfData(currentState ? currentState.pdfData : null, action),
    messageLimitReached: setMessageLimitReached(
      currentState ? currentState.messageLimitReached : false,
      action
    ),
    annotations: setAnnotations(
      currentState ? currentState.annotations : false,
      action
    ),
    annotationsHighlightColor: setAnnotationsHighlightColor(
      currentState
        ? currentState.annotationsHighlightColor
        : {
            color: "hsl(36deg 100% 50%)",
            backgroundColor: "hsl(36deg 100% 88.61%)",
          },
      action
    ),
    storageLimitReached: setStorageLimitReached(
      currentState ? currentState.storageLimitReached : false,
      action
    ),
    documentViewFetchStatus: setDocumentViewFetchStatus(
      currentState
        ? currentState.documentViewFetchStatus
        : {
            fetchDocumentDownload: null,
            fetchDocument: null,
            userConversations: null,
            jumpLocation: null,
          },
      action
    ),
    indexingDocuments: setIndexingDocuments(
      currentState ? currentState.indexingDocuments : null,
      action
    ),
    selectedCopilotChat: setSelectedCopilotChat(
      currentState ? currentState.selectedCopilotChat : null,
      action
    ),
    newCreatedChatId: setNewCreatedChatId(
      currentState ? currentState.newCreatedChatId : null,
      action
    ),
    loadingNewDocument: setLoadingNewDocument(
      currentState ? currentState.loadingNewDocument : false,
      action
    ),
    isCopilotReady: setIsCopilotReady(
      currentState ? currentState.isCopilotReady : false,
      action
    ),
    copilotAiConfig: setCopilotAiConfig(
      currentState
        ? currentState.copilotAiConfig
        : {
            smartdoc_llm_model: "custom",
            smartdoc_temperature: "1.0",
            smartdoc_use_sources: "false",
            smartdoc_is_multidoc: "false",
          },
      action
    ),
    copilotTutorialDismissedByUser: setCopilotAiConfig(
      currentState ? currentState.copilotTutorialDismissedByUser : false,
      action
    ),
    copilotDashboardConfig: setCopilotDashboardConfig(
      currentState
        ? currentState.copilotDashboardConfig
        : {
            showOverlay: false,
            settings: { show: true },
            llmModel: { show: true, active: "custom" },
            usageInfo: { show: true },
            documents: { show: true },
            useSources: { show: true, active: true },
            upload: { show: true },
            delete: { show: true },
            details: { show: true, active: true },
            alwaysChatWithAllDocuments: false,
            colorThemeToggle: { show: false, active: "dark" },
          },
      action
    ),
    rules: [
      {
        id: 1,
        name: "Regel - 1",
        description: "Eine wichtige Automatisierung",
        trigger_count: 3,
      },
    ],
    rule: {
      id: 1,
      name: "Regel - 1",
      description: "Eine wichtige Automatisierung",
      trigger_count: 3,
    },
  };
};

const reducerWrapper = (state: ReduxState, action: any) => {
  if (action.type === ActionConstants.FORCE_REFRESH) {
    state.dialogueGraphs = null;
    state.dialogueGraphBlueprints = null;
    state.dialogueVariables = null;
    state.selectedGraphNode = [];
    state.synonyms = [];
    state.tickets = [];
    state.decisionTrees = null;
    state.faqs = [];
    state.jumpGraphs = [];
    state.cannedResponses = null;
    state.cannedResponse = null;
    state.labels = null;
    state.label = null;
    state.conversationLabels = null;
    state.conversations = null;
    state.conversationsCount = null;
  }

  return appReducer(state, action);
};

export default reducerWrapper;
